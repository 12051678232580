$icon-font-path: "../node_modules/bootstrap-sass/assets/fonts/bootstrap/";

@import "../node_modules/bootstrap/scss/bootstrap";
@import '~bootstrap/dist/css/bootstrap.min.css';
// $fa-font-path: "../node_modules/font-awesome/fonts";
// @import "../node_modules/font-awesome/scss/font-awesome";
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/ngx-toastr/toastr.css";
@import "../node_modules/leaflet/dist/leaflet.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

@font-face {
  font-family: 'MoonHouse';
  src: url('./assets/fonts/moonhouse.eot');
  src: url('./assets/fonts/moonhouse.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/moonhouse.ttf') format('truetype');
}

@import "app/theme/styles/variables";
@import "app/theme/styles/mixins";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/base";

body, p, div, container {
  font-family: Poppins !important;
  letter-spacing: 0.2px;
}

.app.sidebar-fixed .main-content {
  padding-left: 263px !important;
}

.p-toast-top-center {
  left: 50%;
  margin-left: -10em;
  top: 130px !important;
  background-color: #fff;
}

.p-autocomplete {
  display: inline-flex;
  position: relative;
  width: 65% !important;
}

.app.combined .wrapper, .app.combined .wrapper-inner {
  /*background-color: #ffffff !important;*/
  color: #080808 !important;
}

.breadcrumb {
  margin-top: 1%;
}

.main-content {
  width: 99% !important;
  -webkit-box-flex: 1;
  min-height: calc(100vh - (46px + 28px));
  overflow: hidden;
}

body .ui-table .ui-table-tbody > tr {
  background-color: #ffffff;
  color: #333333;
  FONT-SIZE: 13px;
}
/*p-table making header padding null*/
body .ui-table .ui-table-caption {
  border-bottom: 0 none;
  font-weight: 700;
  padding-bottom: 0px;
  padding-top: 0px;
}
/*p-table making header padding null*/
.ui-table .ui-table-tbody > tr:hover {
  background-color: #7ad2f7 !important;
  color: #333333;
  FONT-SIZE: 11px !important;
  line-height: 18px !important;
  cursor: pointer !important;
}

.global_search {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 11px;
}
/*#region For Alternate4 row colors */
.ui-table .ui-table-tbody > tr:nth-child(even):hover {
  background-color: #7ad2f7 !important;
  color: #333333;
  font-size: 11px !important;
  line-height: 18px !important;
  cursor: pointer !important;
}

.ui-table .ui-table-tbody > tr {
  background: #fff !important;
}

.ui-table .ui-table-tbody > tr:nth-child(even) {
  /*background-color: #e6f2f8 !important;*/
}
/*#endregion */
body .ui-table .ui-table-caption {
  border-bottom: 0 none;
  font-weight: 700;
  padding-bottom: 0px;
  padding-top: 0px;
}
/*p-table making header padding null*/
/*-------------------Icons Styles-------------------*/
body .ui-table .ui-table-thead > tr > th {
  padding: 3px 0px 1px 7px !important;
  border: 0.5px solid #9ed9f7 !important;
  font-weight: bold !important;
  color: #0253af !important;
  font-size: 11px !important;
  background-color: #dfeff7 !important;
  line-height: 18px !important;
  cursor: pointer !important;
}

.ui-table .ui-table-tbody > tr > td {
  padding: 1px 0px 0px 6px !important;
}

@-webkit-keyframes trans1 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-250px,0,0);
  }
}

@-moz-keyframes trans1 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-250px,0,0);
  }
}

@keyframes trans1 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-250px,0,0);
  }
}

@-webkit-keyframes trans2 {
  from {
    transform: translate3d(-160px,0,0);
  }

  to {
    transform: translate3d(53px,0,0);
  }
}

@-moz-keyframes trans2 {
  from {
    transform: translate3d(-160px,0,0);
  }

  to {
    transform: translate3d(53px,0,0);
  }
}

@keyframes trans2 {
  from {
    transform: translate3d(-160px,0,0);
  }

  to {
    transform: translate3d(53px,0,0);
  }
}

@-webkit-keyframes trans3 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-220px,0,0);
  }
}

@-moz-keyframes trans3 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-220px,0,0);
  }
}

@keyframes trans3 {
  from {
    transform: translate3d(53px,0,0);
  }

  to {
    transform: translate3d(-220px,0,0);
  }
}

.loader-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;
  visibility: visible;
  opacity: 1;
  -webkit-transition: visibility 0.5s, opacity 0.3s linear;
  -moz-transition: visibility 0.5s, opacity 0.3s linear;
  transition: visibility 0.5s, opacity 0.3s linear;

  &.hide {
    visibility: hidden;
    opacity: 0;
  }

  .loader-container {
    height: 100%;
    width: 100%;
    display: table;

    .loader-inner {
      vertical-align: middle;
      height: 100%;
      display: table-cell;

      .loader {
        width: 80px;
        margin: auto;
        position: relative;
        height: 60px;

        .mask {
          position: absolute;
          overflow: hidden;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;

          .plane {
            background: #DD1B16;
            width: 400%;
            height: 100%;
            position: absolute;
          }
        }

        #top {
          width: 53px;
          height: 15px;
          left: 20px;
          -webkit-transform: skew(-15deg, 0);
          -moz-transform: skew(-15deg, 0);
          transform: skew(-15deg, 0);

          .plane {
            -webkit-animation: trans1 1.3s ease-in infinite 0s backwards;
            -moz-animation: trans1 1.3s ease-in infinite 0s backwards;
            animation: trans1 1.3s ease-in infinite 0s backwards;
          }
        }

        #middle {
          width: 33px;
          height: 15px;
          left: 20px;
          top: 15px;
          -webkit-transform: skew(-15deg, 40deg);
          -moz-transform: skew(-15deg, 40deg);
          transform: skew(-15deg, 40deg);

          .plane {
            transform: translate3d(0px,0,0);
            background: #DD1B16;
            -webkit-animation: trans2 1.3s linear infinite 0.3s backwards;
            -moz-animation: trans2 1.3s linear infinite 0.3s backwards;
            animation: trans2 1.3s linear infinite 0.3s backwards;
          }
        }

        #bottom {
          width: 53px;
          height: 15px;
          top: 30px;
          -webkit-transform: skew(-15deg, 0);
          -moz-transform: skew(-15deg, 0);
          transform: skew(-15deg, 0);

          .plane {
            -webkit-animation: trans3 1.3s ease-out infinite 0.7s backwards;
            -moz-animation: trans3 1.3s ease-out infinite 0.7s backwards;
            animation: trans3 1.3s ease-out infinite 0.7s backwards;
          }
        }

        p.loader-text {
          color: #000;
          position: absolute;
          top: 42px;
          font-family: 'MoonHouse';
          font-size: 16px;

          span {
            color: #DD1B16;
          }
        }
      }
    }
  }
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 0px;

  &:hover {
    background: #cccccc;
  }

  &:active {
    background: #888888;
  }
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 0px;

  &:hover {
    background: #666666;
  }

  &:active {
    background: #333333;
  }
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/*#region ramesh alli */

.main-content {
  padding: 25px;
  -webkit-box-flex: 1;
  min-height: calc(100vh - (46px + 28px));
  overflow: hidden;
  background-color: #fff !important;
}

.app.blue .breadcrumb a {
  color: #3E50B4;
  font-family: 'Poppins' !important;
  font-size: 12px !important;
  letter-spacing: 0.5px !important;
}

.b_stng {
  font-weight: bolder;
  font-family: 'Poppins';
  font-size: 12px;
  color: #000;
  letter-spacing: 0.5px;
}



fieldset.scheduler-border {
  border: 1px solid #0080cf !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
  display: block;
  width: auto;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: poppins;
  font-size: 18px;
  color: #005f9a;
  letter-spacing: 0.5px;
}


.heading_1 {
  font-family: 'Poppins';
  font-size: 18px;
  letter-spacing: 1px;
  color: #000;
  font-weight: bold;
  padding-bottom: 1.5rem;
  padding-top: 0.5rem;
}

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  font-family: 'Poppins';
  letter-spacing: 0.7px;
  color: #000;
}

.small, small {
  font-size: 67%;
  font-weight: 400;
  letter-spacing: 0.1px;
}


/*.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0rem 0rem 0.2rem 0.5rem !important;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
  width: 10rem !important;
}*/

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 0rem 1rem !important;
  font-family: 'Poppins' !important;
  font-size: 11px !important;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0rem 1rem !important;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
  line-height: 1;
  padding-bottom: 0.2rem !important;
  font-family: 'Poppins' !important;
  letter-spacing: 0.5px !important;
}

.form-control-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 0 !important;
  height: 2.7rem !important;
  letter-spacing: 0.5px;
  font-family: 'Poppins' !important;
}

.col-form-label {
  padding-top: 0;
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0rem !important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  height: 2.7rem !important;
}

.app.grey .breadcrumb a {
  color: #3E50B4;
  font-family: 'Poppins' !important;
  font-size: 18px !important;
  letter-spacing: 0.5px !important;
  cursor:pointer;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
  font-family: 'roboto' !important;
  font-size: 19px !important;
  letter-spacing: 0.5px !important;
  color: black !important;
}

@media only screen and (max-width:600px) {
  .breadcrumb-item + .breadcrumb-item {
    padding-left: .5rem;
    font-family: 'roboto' !important;
    font-size: 14px !important;
    letter-spacing: 0.5px !important;
    color: black !important;
  }
}

.app.grey .btn-primary {
  color: #fff;
  background-color: #3E50B4;
  border-color: #3E50B4;
  width: 8rem !important;
  font-family: 'Poppins';
  font-size: 11px !important;
  letter-spacing: 0.5px !important;
}

.app.green .btn-primary {
  color: #fff;
  background-color: #3E50B4;
  border-color: #3E50B4;
  width: 8rem !important;
  font-family: 'Poppins' !important;
  font-size: 11px !important;
  letter-spacing: 0.5px !important;
}

.app.green .menu-wrapper .card a {
  color: #ffffff !important;
  border-bottom: 1px solid rgba(0, 120, 106, 0.5);
  box-shadow: 0 1px 0 0 rgb(255 255 255 / 10%);
  font-family: 'Poppins' !important;
  font-size: 13px !important;
  letter-spacing: 0.5px !important;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
  font-size: 12px;
  letter-spacing: 0.5px !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #a6d5fa;
  line-height: 2 !important;
}
.tableheadericons {
  width: 1rem;
  margin-top: -0.3rem;
  margin-right: 0.5rem;
  text-align: center;
}

.stateicon {
  width: 40px;
  text-align: center;
  max-width: 0;

}


/*#endregion */


.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #6eb2e4 !important;
  color: white !important;
}
body .ui-table .ui-table-thead > tr > th {
  padding: 3px 0px 1px 7px !important;
  border: 0.5px solid #9ed9f7 !important;
  font-weight: bold !important;
  color: #0253af !important;
  font-size: 11px !important;
  background-color: #dfeff7 !important;
  line-height: 18px !important;
  cursor: pointer !important;
}

.ui-table .ui-table-tbody > tr > td {
  padding: 1px 0px 0px 6px !important;
}

.ui-table .ui-table-tbody > tr > td {
  background-color: inherit;
  border: 1px solid #a3d0e4 !important;
}

.ui-paginator {
  background-color: #dfeff75e !important;
  border: 1px solid #9ed9f7 !important;
  padding: 0;
}

::-webkit-scrollbar-track {
  background-color: #fff;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background: #7ad2f7;
  cursor: pointer !important;
}

body .ui-table .ui-table-tbody > tr {
  background-color: #ffffff;
  color: #333333;
  FONT-SIZE: 11px !important;
  line-height: 18px !important;
}

.ui-table .ui-table-caption, body .ui-table .ui-table-summary {
  background-color: #dfeff7 !important;
  color: #333333;
  border: 1px solid #bbdceb !important;
  padding: 0.571em 1em;
  text-align: center;
}

section.content {
  margin: 67px 15px 0 275px !important;
  -moz-transition: .5s;
  -o-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
}

body .pi {
  font-size: 11px !important;
}

body .ui-paginator .ui-paginator-first, body .ui-paginator .ui-paginator-prev, body .ui-paginator .ui-paginator-next, body .ui-paginator .ui-paginator-last {
  color: #335ab6 !important;
  height: 2.286em;
  min-width: 2.286em;
  border: 0 none;
  line-height: 2.286em;
  padding: 0;
  margin: 0;
  vertical-align: top;
  border-radius: 0;
}

body, html {
  background-color: #ffffff !important;
}

.global_search {
  font-size: 14px !important;
  letter-spacing: 0.5px !important;
}

.fa-search:before {
  content: "\f002";
  color: #335ab6;
}

/*.btn:not(.btn-link):not(.btn-circle) {
  box-shadow: 0 2px 5px rgba(0,0,0,0.16), 0 2px 10px rgba(0,0,0,0.12);
  border: 0;
  font-size: 13px;
 
}
  */

body .ui-autocomplete .ui-autocomplete-input {
  width: 140%;
}
